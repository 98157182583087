import React from 'react';

import LogoBlack from './images/logoblack.png';
import { X as Close, CheckCircle, ChevronRight} from 'react-feather';
import { slide as Menu } from 'react-burger-menu';
import {Link} from 'react-router-dom';
import { disableBodyScroll } from 'body-scroll-lock';

import {message, Spin} from 'antd';
import axios from 'axios';
import LogoWhite from './images/logowhite.png';


class Blog extends React.Component{

	state = {
        scrollPastHeader: true,
        eventName: '',
        eventPhone: '',
        eventEmail: '',
        eventInfo: '',
        reserveSent: false,
		isReserving: false,
		loadingPosts: false,
		posts: [],
		totalPages: 0,
		currentPage: 1
	}

	headerRef = React.createRef();
	reserveRef = React.createRef();
	eventsRef = React.createRef();
	mainRef = React.createRef();


	menu = () => (
		<div className="flex flex-col justify-around items-center h-full">
			<div className="hidden sm:block">
				<img src={LogoWhite} style={{
					width: 176,
					height: 38
				}} alt=""/>
			</div>
			<div className="">
				<ul className="flex freight-dl flex-col sm:flex-row items-center justify-center text-3xl -my-4 sm:-my-0 sm:-mx-4 h-full">
					<li><Link to="/blog"className="block py-4 sm:py-0 sm:px-4">Blog</Link></li>
					<li><Link to="/contact" className="block py-4 sm:py-0 sm:px-4">Contact</Link></li>
					{/* <li><a href="" className="block px-4">Events</a></li> */}
					{/* <li><a href="" className="block px-4">Essentials</a></li> */}
					<li><Link to="/#reserve" className="block py-4 sm:py-0 sm:px-4">Reserve</Link></li>
				</ul>
			</div>
			<div className="">
				<ul className="flex freight-dl items-center justify-center text-xl -mx-4 h-full">
					<li><a href="https://www.facebook.com/pearlygategardens/" target="_blank" className="block px-4">Facebook</a></li>
					<li><a href="https://www.instagram.com/pearlygategardens/" target="_blank" className="block px-4">Instagram</a></li>
				</ul>
			</div>
		</div>
		
	);
    
    componentDidMount() {

		disableBodyScroll(this.mainRef.current);

		this.loadPosts();

	}
	
	loadPosts = async () => {

		const {currentPage} = this.state;

		try {

			const res = await axios.get(`https://blog.pearlygategardens.com/wp-json/wp/v2/posts?_embed&per_page=12&page=${currentPage}`);

			this.setState({
				loadingPosts: false,
				totalPages: parseInt(res.headers['x-wp-totalpages']),
				posts: [...res.data]
			});

		} catch(e) {

			console.log(e);

			this.setState({
				loadingPosts: false
			});

		};

	}
    
    makeReservation = async () => {

		const {
			eventName,
			eventPhone,
			eventEmail,
			eventInfo,
		} = this.state;

		if (
			!eventInfo ||
			!eventName ||
			!eventPhone ||
			!eventEmail
		) {

			message.error('Kindly complete the reservation form to continue');

			return;

		}

		this.setState({isReserving: true});

		const url = "https://pearly.sirakoff.now.sh/sendmail";

		try {

			const res = await axios.post(`${url}`, {
				receipient: eventEmail,
				type: 'Contact',
				eventName,
				eventPhone,
				eventEmail,
				eventInfo
			});

			this.setState({
				reserveSent: true,
				isReserving: false
			});

		} catch (e) {

			message.error('An error occurred, please try again');

			this.setState({
				isReserving: false
			});

		}

	}


	render() {

        const {
            scrollPastHeader,
            menuOpen,
			mobileMenu,
			loadingPosts,
			posts,
			totalPages,
			currentPage
        } = this.state;
        
        return (
            <React.Fragment>
				<Menu styles={{
					bmMenuWrap: {
						width: '100vw',
						transform: 'translate3d(0px, -100%, 0px)'
					}
				}} isOpen={menuOpen} onStateChange={(e) => this.setState({menuOpen: e.isOpen})}>
					<div className="text-white h-full sm:block hidden" style={{
						backgroundColor: '#202020'
					}}>
						<div className="py-6">
							<a className="px-4 flex neue-roman justify-end items-center uppercase" href="#" onClick={(e) => {

								e.preventDefault();

								this.setState({menuOpen: false});

							}}>
								<span className="">
									Close
								</span>
								<span className="pl-3">
									<Close />
								</span>
							</a>
						</div>
						{this.menu()}
					</div>
				</Menu>
				<div ref={this.mainRef} style={{
                    backgroundColor: '#f5f5f5'
                }} className="main-view tracking-wide w-full h-full mx-auto">
				
					<div className={[
                        'fixed',
						"w-full",
						"z-30",
						mobileMenu ? 'h-screen': '',
						scrollPastHeader ? 'shadow' : ''
					].join(' ')} style={{
						backgroundColor: scrollPastHeader || mobileMenu ? '#fbfaf9': '',
						color: scrollPastHeader || mobileMenu ? '#171717': '#fff'

					}}>
						<div className="container mx-auto px-3 py-4">
							<div className="flex justify-between items-center">
								<div className="items-start montaga flex">
                                    <Link to="/">
                                        <img src={LogoBlack} style={{
                                            width: 176,
                                            height: 38
                                        }} alt=""/>
                                    </Link>
									{/* <div className="uppercase sm:text-lg">
										Pearly gate
									</div>
									<div className="hidden sm:inline uppercase px-2 text-xs">gardens</div> */}
								</div>
								{mobileMenu ? <a className="flex neue-roman justify-center items-center uppercase" href="#" onClick={(e) => {

									e.preventDefault();

									this.setState({mobileMenu: false});

									}}>
									<span className="">
										Close
									</span>
									<span className="pl-3">
										<Close />
									</span>
								</a> : <a className="inline sm:hidden" href="#" onClick={(e) => {

									e.preventDefault();

									this.setState({mobileMenu: true});

								}}>
									<div className="flex flex-col">
                                        <div style={{
                                            marginTop: '3px',
                                            marginBottom: '3px',
                                            width: '23px',
                                            height: 0,
                                            border: `solid 0.5px ${scrollPastHeader || mobileMenu ? '#171717': '#fff'}`
                                        }}></div>
                                        <div style={{
                                            marginTop: '3px',
                                            marginBottom: '3px',
                                            width: '23px',
                                            height: 0,
                                            border: `solid 0.5px ${scrollPastHeader || mobileMenu ? '#171717': '#fff'}`
                                        }}></div>
                                    </div>
								</a>}
								<ul className="hidden sm:flex list-reset items-center sm:-mx-4 neue-roman">
									<li className="hidden sm:inline uppercase"><a className="px-4" href="/#reserve" style={{
										fontSize: 15,
										lineHeight: '1.18',
										letterSpacing: '1.7px'
									}}>Reserve</a></li>
									<li className="uppercase">
										<a className="sm:px-4 flex items-center" href="#" onClick={(e) => {

											e.preventDefault();

											this.setState({menuOpen: !menuOpen});
											
										}}>
											<span className="" style={{
												fontSize: 15,
												lineHeight: '1.18',
												letterSpacing: '1.7px'
											}}>
												Menu
											</span>
											<span className="hidden sm:inline sm:pl-3">
												<div className="flex flex-col">
                                        <div style={{
                                            marginTop: '3px',
                                            marginBottom: '3px',
                                            width: '23px',
                                            height: 0,
                                            border: `solid 0.5px ${scrollPastHeader || mobileMenu ? '#171717': '#fff'}`
                                        }}></div>
                                        <div style={{
                                            marginTop: '3px',
                                            marginBottom: '3px',
                                            width: '23px',
                                            height: 0,
                                            border: `solid 0.5px ${scrollPastHeader || mobileMenu ? '#171717': '#fff'}`
                                        }}></div>
                                    </div>
											</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
						{mobileMenu ? <div className="sm:hidden h-screen" style={{
							backgroundColor: '#fbfaf9',
							color: '#171717'
						}}>
							<div className="container mx-auto px-3 sm:px-0 py-8 h-full">
								{this.menu()}
							</div>
						</div>: null}
					</div>
                    <div className="w-full flex flex-wrap pt-20 h-screen" style={{
                        
                    }}>
                        {!loadingPosts && !posts.length ? null : <div className="py-16 px-4 w-full" style={{
							backgroundColor: '#fbfaf9'
						}}>
							<div className="container mx-auto px-3">
								<div className="">
									<div className="freight-bl sm:text-5xl text-3xl" style={{
										lineHeight: '1.22',
										letterSpacing: '0.39px',
										color: '#454545'
									}}>Essential and Pearls</div>
									<div className="my-8" style={{
										width: 63.8,
										height: 0,
										border: 'solid 1px #454545'
									}}></div>
									<p className="freight-medium text-base sm:text-xl" style={{
										maxWidth: 519,
										lineHeight: '1.67',
										letterSpacing: '0.38px',
										color: '#959595'
									}}>
										{/* Take a stroll down the past events hosted within the premises. A variety 
										of events ranging from very personal weddings to full on private 
										executive parties. */}
										Take a stroll through stories and tips from seasoned event planners and event guests we’ve hosted.
									</p>
								</div>
								<div className="flex flex-wrap -mx-6 mt-10">
									{loadingPosts ? <div className="flex h-full items-center justify-center py-10">
										<Spin />
									</div>  : posts.map((e, index) => {

										const hasFeatured = e.featured_media !== 0;

										let image = hasFeatured ? e._embedded['wp:featuredmedia'].filter((i) => i.id === e.featured_media): null

										return (
											<div className="w-full my-6 md:w-1/2 lg:w-1/3 px-6" key={e.id}>
												<div style={{
													height: '280px',
													backgroundSize: 'cover',
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',
													backgroundImage: image ? `url(${image[0].source_url})`: ''
												}} className="w-full bg-gray-200"></div>
												<div className="mt-6">
													<div className="freight-medium" style={{
														fontSize: 24,
														lineHeight: '1.29',
														letterSpacing: '0.26px',
														color: '#171717'
													}}>{e.title.rendered}</div>
													<div className="my-2 freight-dl" style={{
														fontSize: 18,
														lineHeight: '1.62',
														letterSpacing: '0.17px',
														color: '#5c5c5c'
													}} dangerouslySetInnerHTML={{__html: e.excerpt.rendered}}></div>
													<a target="_blank" href={`http://blog.pearlygategardens.com/${e.slug}`} className="py-3 neue-roman" style={{
														fontSize: 16,
														lineHeight: '1.18',
														letterSpacing: '0.77px',
														color: '#ff7045'
													}}>Read More</a>
												</div>
											</div>
										);

									})}
								</div>
								<div className="w-full h-1 mt-6 mb-12" style={{
									borderBottom: '0.3px solid #959595'
								}}></div>
								{totalPages > 12 && currentPage < totalPages ? <button onClick={() => this.props.history.push('/blog')} className="neue-md px-6 py-3 uppercase block mx-auto" style={{
									color: '#f5f5f5',
									fontSize: 12,
									color: '#171717',
									border: 'solid 1px #171717',
									letterSpacing: '1.95px',
									backgroundColor: 'transparent'
								}}>
									More Stories
								</button>: null}
							</div>
						</div>}
                    </div>
                </div>
            </React.Fragment>
        );

    }

}

export default Blog;