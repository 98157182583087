import './setupTests.js'
import React from 'react';
import { render } from 'react-snapshot';
import "antd/lib/date-picker/style/css";
import "antd/lib/time-picker/style/css";
import "antd/lib/spin/style/css";
import "antd/lib/message/style/css";
import './App.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';


import ReactGA from 'react-ga';

ReactGA.initialize('UA-147729782-1');


const history = createBrowserHistory();

history.listen(location => {
    
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname);
    
});

render(
    <Router history={history}>
        <App />
    </Router>
, document.getElementById('root'));
serviceWorker.unregister();
