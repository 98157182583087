import React from 'react';
import Background from './images/header-bg-compressor.webp';
import Diner from './images/diner.webp';
import Calmness from './images/calmness.webp'
import Essentials from './images/essentials.webp'
import SecurityIcon from './images/security.svg';
import AccessibilityIcon from './images/accessibility.svg';
import EventsIcon from './images/events.svg';
import {Link} from 'react-router-dom';
import {X as Close, CheckCircle} from 'react-feather';
import { Waypoint } from 'react-waypoint';
import { slide as Menu } from 'react-burger-menu';
import moment from 'moment';
import {DatePicker, TimePicker, Spin, message} from 'antd';
import axios from 'axios';
import Slider from "react-slick";
import { scrollTo } from 'scroll-js';
import { disableBodyScroll } from 'body-scroll-lock';

import Video from './images/pearls.mp4';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import LogoWhite from './images/logowhite.png';
import LogoBlack from './images/logoblack.png';

import GalleryImg01 from './images/gallery/01.JPG';
import GalleryImg02 from './images/gallery/02.JPG';
import GalleryImg03 from './images/gallery/03.JPG';
import GalleryImg05 from './images/gallery/05.JPG';
import GalleryImg06 from './images/gallery/06.JPG';
import GalleryImg04 from './images/gallery/04.JPG';

const images = [
	GalleryImg01,
	GalleryImg02,
	GalleryImg03,
	GalleryImg04,
	GalleryImg05,
	GalleryImg06,
];


const spaces = ['The Garden', 'Inner Space'];

const events = [{
	title: 'The Accra Goods Market fair, held on the fields',
	description: 'Creating a platform for food purveyors, fashion and design goods traders to showcase their wares. Accra Goods Market stands as an innovative product that conceptualises the idea of trading'
}, {
	title: 'Movie in the park get-together, took place in our inner space.',
	description: 'Very few public events such as these occur in Ghana. A partnership between Vodafone and Uncle Ebo Whyte, a large display was mounted within our garden fields to accommodate viewers,'
}, {
	title: 'The union of Pricilla Boateng & Frimpong Agyeman',
	description: 'All marriages are sacred, and a once in a lifetime event, meaning we only get one chance to make it right. In this event, we hosted and officiated the wedding ceremony between Mr & Mrs Agyeman.'
}, {
	title: 'Movie in the park get-together, took place in our inner space.',
	description: 'Very few public events such as these occur in Ghana. A partnership between Vodafone and Uncle Ebo Whyte, a large display was mounted within our garden fields to accommodate viewers,'
}];

const availabilityTitle = {
	fontSize: 15,
	// lineHeight: '4.47',
	letterSpacing: '0.45',
	color: '#454545'
};

class App extends React.Component{

	state = {
		scrollPastHeader: false,
		availableDate: moment().add(1, 'day').format('YYYY-MM-DD'),
		minDate: moment().add(1, 'day').format('YYYY-MM-DD'),
		availableTime: moment().format('HH:mm A'),
		eventDate: moment().add(1, 'day').format('YYYY-MM-DD'),
		eventName: '',
		eventPhone: '',
		eventEmail: '',
		eventType: '',
		eventInfo: '',
		isReserving: false,
		eventAgree: false,
		photoIndex: 0,
		photosIsOpen: false,
		reserveSent: false,
		loadingPosts: true,
		posts: [],
		totalPages: 0,
	}

	headerRef = React.createRef();
	reserveRef = React.createRef();
	eventsRef = React.createRef();
	mainRef = React.createRef();

	scrollToReserve = () => {

		scrollTo(this.mainRef.current, { top: this.reserveRef.current.offsetTop - 91 }).then(function() {
			// window has scrolled 500 pixels down the page
		});

	}

	scrollToEvents = () => {

		// console.log(this.headerRef.current.offsetTop);
		
		window.scrollTo(0, this.eventsRef.current.offsetTop - 91);

	}

	async componentDidMount() {

		disableBodyScroll(this.mainRef.current);

		try {

			const res = await axios.get('https://blog.pearlygategardens.com/wp-json/wp/v2/posts?_embed&per_page=3');

			// console.log(res);

			this.setState({
				loadingPosts: false,
				totalPages: parseInt(res.headers['x-wp-totalpages']),
				posts: [...res.data]
			});

		} catch(e) {

			console.log(e);

			this.setState({
				loadingPosts: false
			});

		}

	}

	menu = () => (
		<div className="flex flex-col justify-around items-center h-full">
			<div className="hidden sm:block">
				<img src={LogoWhite} style={{
					width: 176,
					height: 38
				}} alt=""/>
			</div>
			<div className="">
				<ul className="flex freight-dl flex-col sm:flex-row items-center justify-center text-3xl -my-4 sm:-my-0 sm:-mx-4 h-full">
					<li><Link to="/blog" className="block py-4 sm:py-0 sm:px-4">Blog</Link></li>
					<li><Link to="/contact" className="block py-4 sm:py-0 sm:px-4">Contact</Link></li>
					{/* <li><a href="" className="block px-4">Events</a></li> */}
					{/* <li><a href="" className="block px-4">Essentials</a></li> */}
					<li><a href="" className="block py-4 sm:py-0 sm:px-4" onClick={(e) => {

						e.preventDefault();

						this.setState({mobileMenu: false, menuOpen:false}, this.scrollToReserve);

					}}>Reserve</a></li>
				</ul>
			</div>
			<div className="">
				<ul className="flex freight-dl items-center justify-center text-xl -mx-4 h-full">
					<li><a href="https://www.facebook.com/pearlygategardens/" target="_blank" className="block px-4">Facebook</a></li>
					<li><a href="https://www.instagram.com/pearlygategardens/" target="_blank" className="block px-4">Instagram</a></li>
				</ul>
			</div>
		</div>
		
	);

	onEnter = () => this.setState({scrollPastHeader: false})
	onLeave = () => this.setState({scrollPastHeader: true})

	onPositionChange = () => ({currentPosition}) => {

		// console.log(currentPosition, Waypoint.above);

		this.setState({scrollPastHeader: currentPosition === Waypoint.above? true: false});

	}

	checkAvailability = async (isReserve) => {

		const _format = 'YYYY-MM-DD HH:mm A';
		const format = 'YYYY-MM-DD[T]HH:mm';
		const {availableDate, availableTime, eventDate} = this.state;
		const d =  isReserve ? moment(eventDate, 'YYYY-MM-DD') : moment(`${availableDate} ${availableTime}`, _format);

		if (d.isSameOrBefore(moment())) {

			return message.error('Selected date is invalid');

		}

		const url = "https://us-central1-domains-b539a.cloudfunctions.net/api/availability";
		const start_date = d.format(format);
		const end_date = d.endOf('day').format(format);

		this.setState({
			checkingAvailability: true
		});

		try {

			const res = await axios.post(`${url}`, {
				min: start_date,
				max: end_date
			});

			this.setState({
				checkingAvailability: false,
				isReserving: false
			});

			if (!res.data.calendar.length) {

				this.setState({
					eventDate: d.format('YYYY-MM-DD')
				}, this.scrollToReserve);

			} else {

				message.error('The selected date and time has already been booked');

			}

		} catch (e) {

			message.error('An error occurred, please try again');

			this.setState({
				checkingAvailability: false,
				isReserving: false
			});

		}



	}

	makeReservation = async () => {

		const {
			eventDate,
			eventName,
			eventPhone,
			eventEmail,
			eventType,
			eventInfo
		} = this.state;

		if (
			!eventDate ||
			!eventName ||
			!eventPhone ||
			!eventEmail ||
			!eventType
		) {

			message.error('Kindly complete the reservation form to continue');

			return;

		}

		this.setState({isReserving: true});

		await this.checkAvailability(true);

		const url = "https://us-central1-domains-b539a.cloudfunctions.net/api/sendmail";

		try {

			const res = await axios.post(`${url}`, {
				receipient: eventEmail,
				type: 'Reservation',
				eventDate,
				eventName,
				eventPhone,
				eventEmail,
				eventType,
				eventInfo
			});

			this.setState({
				reserveSent: true,
				isReserving: false
			});

		} catch (e) {

			message.error('An error occurred, please try again');

			this.setState({
				isReserving: false
			});

		}

	}

	render() {

		const {
			photoIndex,
			photosIsOpen,
			scrollPastHeader,
			menuOpen,
			mobileMenu,
			availableDate,
			minDate,
			availableTime,
			checkingAvailability,
			eventDate,
			eventName,
			eventPhone,
			eventEmail,
			eventType,
			eventInfo,
			eventAgree,
			reserveSent,
			isReserving,
			loadingPosts,
			posts,
			totalPages
		} = this.state;
		
		return (
			<React.Fragment>
				{photosIsOpen && (
					<Lightbox
						mainSrc={images[photoIndex]}
						nextSrc={images[(photoIndex + 1) % images.length]}
						prevSrc={images[(photoIndex + images.length - 1) % images.length]}
						onCloseRequest={() => this.setState({ photosIsOpen: false })}
						onMovePrevRequest={() =>
							this.setState({
								photoIndex: (photoIndex + images.length - 1) % images.length,
							})
						}
						onMoveNextRequest={() =>
							this.setState({
								photoIndex: (photoIndex + 1) % images.length,
							})
						}
					/>
				)}
				<Menu styles={{
					bmMenuWrap: {
						width: '100vw',
						transform: 'translate3d(0px, -100%, 0px)'
					}
				}} isOpen={menuOpen} onStateChange={(e) => this.setState({menuOpen: e.isOpen})}>
					<div className="text-white h-full sm:block hidden" style={{
						backgroundColor: '#202020'
					}}>
						<div className="py-6">
							<a className="px-4 flex neue-roman justify-end items-center uppercase" href="#" onClick={(e) => {

								e.preventDefault();

								this.setState({menuOpen: false});

							}}>
								<span className="">
									Close
								</span>
								<span className="pl-3">
									<Close />
								</span>
							</a>
						</div>
						{this.menu()}
					</div>
				</Menu>
				<div ref={this.mainRef} className="main-view tracking-wide w-full h-full mx-auto">
				
					<div className={[
						"fixed",
						"w-full",
						"z-30",
						mobileMenu ? 'h-screen': '',
						scrollPastHeader ? 'shadow' : ''
					].join(' ')} style={{
						backgroundColor: scrollPastHeader || mobileMenu ? '#fbfaf9': '',
						color: scrollPastHeader || mobileMenu ? '#171717': '#fff'

					}}>
						<div className="container mx-auto px-3 py-4">
							<div className="flex justify-between items-center">
								<div className="items-start montaga flex">
									<img src={scrollPastHeader || mobileMenu ? LogoBlack : LogoWhite} style={{
                                        width: 176,
                                        height: 38
                                    }} alt=""/>
								</div>
								{mobileMenu ? <a className="flex neue-roman justify-center items-center uppercase" href="#" onClick={(e) => {

									e.preventDefault();

									this.setState({mobileMenu: false});

									}}>
									<span className="">
										Close
									</span>
									<span className="pl-3">
										<Close />
									</span>
								</a> : <a className="inline sm:hidden" href="#" onClick={(e) => {

									e.preventDefault();

									this.setState({mobileMenu: true});

								}}>
									<div className="flex flex-col">
										<div style={{
											marginTop: '3px',
											marginBottom: '3px',
											width: '23px',
											height: 0,
											border: `solid 0.5px ${scrollPastHeader || mobileMenu ? '#171717': '#fff'}`
										}}></div>
										<div style={{
											marginTop: '3px',
											marginBottom: '3px',
											width: '23px',
											height: 0,
											border: `solid 0.5px ${scrollPastHeader || mobileMenu ? '#171717': '#fff'}`
										}}></div>
									</div>
								</a>}
								<ul className="hidden sm:flex list-reset items-center sm:-mx-4 neue-roman">
									<li className="hidden sm:inline uppercase"><a className="px-4" href="#" style={{
										fontSize: 15,
										lineHeight: '1.18',
										letterSpacing: '1.7px'
									}} onClick={(e) => {

										e.preventDefault();

										this.scrollToReserve();

									}}>Reserve</a></li>
									<li className="uppercase">
										<a className="sm:px-4 flex items-center" href="#" onClick={(e) => {

											e.preventDefault();

											this.setState({menuOpen: !menuOpen});
											
										}}>
											<span className="" style={{
												fontSize: 15,
												lineHeight: '1.18',
												letterSpacing: '1.7px'
											}}>
												Menu
											</span>
											<span className="hidden sm:inline sm:pl-3">
												<div className="flex flex-col">
													<div style={{
														marginTop: '3px',
														marginBottom: '3px',
														width: '23px',
														height: 0,
														border: `solid 0.5px ${scrollPastHeader || mobileMenu ? '#171717': '#fff'}`
													}}></div>
													<div style={{
														marginTop: '3px',
														marginBottom: '3px',
														width: '23px',
														height: 0,
														border: `solid 0.5px ${scrollPastHeader || mobileMenu ? '#171717': '#fff'}`
													}}></div>
												</div>
											</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
						{mobileMenu ? <div className="sm:hidden h-screen" style={{
							backgroundColor: '#fbfaf9',
							color: '#171717'
						}}>
							<div className="container mx-auto px-3 sm:px-0 py-8 h-full">
								{this.menu()}
							</div>
						</div>: null}
					</div>

					<Waypoint
						topOffset={'140px'}
						fireOnRapidScroll={true}
						scrollableAncestor={this.mainRef.current}
						onEnter={this.onEnter}
						onLeave={this.onLeave}
						onPositionChange={this.onPositionChange}
					>
						<div ref={this.headerRef} className="min-h-screen px-3 w-full text-white flex flex-col items-center justify-end relative" style={{
							backgroundSize: 'cover',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							backgroundImage:`url(${Background})`
						}}>
							<div className="absolute left-0 right-0 top-0 bottom-0 z-10" style={{
								backgroundColor: 'rgba(0,0,0,0.35)'
							}}></div>
							<div className="z-20 flex flex-col items-center w-full my-8 sm:my-16">
								<div className="flex items-center">
									<div className="" style={{
										width: 90.8,
										height: 0,
										borderBottom: 'solid 1px #a7a7a7'
									}}></div>
									<span className="px-6 freight-dl italic" style={{
										fontSize: 25,
										fontWeight: 300,
										lineHeight: '1.28',
										letterSpacing: 2.5
									}}>the</span>
									<div className="" style={{
										width: 90.8,
										height: 0,
										borderBottom: 'solid 1px #a7a7a7'
									}}></div>
								</div>
								<p className="freight-dl text-center sm:text-6xl text-4xl">Pearly Gate Gardens</p>
								<p className="neue-md leading-none sm:leading-normal" style={{
									fontSize: 18,
									letterSpacing: 3.6
								}}>EVENT CENTRE</p>
								<div className="availability mt-6 sm:mt-12 px-6 w-full">
									<div className="text-black flex-wrap flex items-center lg:h-16 bg-white max-w-6xl w-full mx-auto rounded overflow-hidden">
										<div className="border-b lg:border-b-0 md:border-r w-full md:w-1/2  lg:w-1/4 h-full flex flex-col lg:flex-row sm:items-center justify-center px-6 py-3">
											<p className="neue-roman font-bold lg:mr-6" style={availabilityTitle}>
												Choose Space
											</p>
											<select value="garden" onChange={() => {}} name="" id="">
												<option value="garden">The Garden</option>
												<option value="inner-space">Inner Space</option>
											</select>
										</div>
										<div className="border-b lg:border-b-0 lg:border-r w-full md:w-1/2 lg:w-1/4 h-full flex flex-col lg:flex-row sm:items-center justify-center px-6 py-3">
											<p className="neue-roman font-bold lg:mr-6" style={availabilityTitle}>Select Date</p>
											<input value={availableDate} min={minDate} onChange={(e) => this.setState({availableDate: e.target.value})} className="inline-block sm:hidden bg-white w-full" type="date" name="" id=""/>
											<DatePicker className="hidden sm:inline-block" disabledDate={(date) => date.isSameOrBefore(moment())} onChange={(d) => this.setState({availableDate: d.format('YYYY-MM-DD')})} style={{width: 77}} format="MMM [-] DD" value={moment(availableDate, 'YYYY-MM-DD')} />
										</div>
										<div className="border-b lg:border-b-0 md:border-r w-full md:w-1/2 lg:w-1/4 h-full flex flex-col lg:flex-row sm:items-center justify-center px-6 py-3">
											<p className="neue-roman font-bold lg:mr-6" style={availabilityTitle}>
												Time
											</p>
											<input value={moment(availableTime, 'HH:mm A').format('HH:mm')} onChange={(e) => this.setState({availableTime: moment(e.target.value, 'HH:mm').format('HH:mm A')})} className="inline-block sm:hidden bg-white w-full" type="time" name="" id=""/>
											<TimePicker className="hidden sm:inline-block" style={{width: 75}} format="HH:mm A" value={moment(availableTime, 'HH:mm A')} onChange={(d) => this.setState({availableTime: d.format('HH:mm A')})} />
										</div>
										<div className="w-full lg:w-1/4 md:w-1/2 h-full flex flex-col items-center justify-center">
											<button onClick={() => this.checkAvailability()} style={{
												color: '#f5f5f5',
												letterSpacing: '1.95px',
												backgroundColor: '#454545'
											}} className="uppercase neue-md w-full h-full px-3 py-3">
												{checkingAvailability? <Spin /> : 'Check Availability' }
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Waypoint>
					<div ref={this.eventsRef} className="w-full px-4" style={{
						backgroundColor: '#fbfaf9'
					}}>
						<div className="container mx-auto py-16 px-3">
							<div className="max-w-3xl w-full mx-auto">
								<div className="flex flex-col sm:items-center sm:justify-center sm:text-center">
									<div className="neue-light mb-8 flex items-center" style={{
										fontSize: 15,
										lineHeight: 1.11,
										letterSpacing: 0.19,
										color: "#454545"
									}}>
										Luxury
										<span className="w-1 h-1 rounded-full inline-block mx-2" style={{background: '#454545'}}></span>
										Premium
										<span className="w-1 h-1 rounded-full inline-block mx-2" style={{background: '#454545'}}></span>
										Experience
									</div>
									<div className="freight-dl text-black sm:text-6xl text-4xl" style={{
										lineHeight: 1.15,
										letterSpacing: 0.2
									}}>Atmosphere bejewelled with nature and modern decor</div>
									<div className="mt-8" style={{
										width: 52.8,
										height: 0,
										border: 'solid 0.4px #a7a7a7'
									}}></div>
								</div>
							</div>
							<div className="max-w-4xl my-8 w-full mx-auto sm:text-center freight-dl text-lg sm:text-2xl" style={{
								lineHeight: 1.44,
								letterSpacing: 0.2,
								color: '#171717'
							}}>
								<div className="">
									The fusion of nature’s best coupled with a minimal modern space allows attendees to enjoy 
									the best of both worlds, in a seamless fashion. Evergreen lush fields provide eerie comfort and relaxing 
									atmosphere necessary to accommodate any kind of event.
								</div>
							</div>
						</div>
					</div>
					<div className="w-full px-4 py-16" style={{
						backgroundImage: "#fff"
					}}>
						<div className="container mx-auto px-3">
							<Slider
								dots={true}
								dotsClass="slick-dot w-full mt-6 list-reset items-center"
								customPaging={(i) => {
									return (
										// <div className="w-full sm:w-3/5 mt-12" style={{
										// }}>
										// 	<ul className="list-reset flex items-center sm:mr-8" style={{
										// 		borderTop: 'solid 0.4px #9f9f9f'
										// 	}}>
												
										// 		<li className="uppercase mr-6 pt-4 relative neue-roman" style={{
										// 			top: -1,
										// 			color: '#171717',
										// 			borderTop: 'solid 2px #171717',
										// 			fontSize: 16,
										// 			lineHeight: 2.25,
										// 			letterSpacing: '1.92px',
										// 		}}>
										// 			Inner Space
										// 		</li>
										// 	</ul>
										// </div>
										<span className="uppercase text-sm">
											{spaces[i]}
										</span>
									);
								}}
							>
								<div>
									<div className="flex flex-col lg:flex-row items-center">
										<div className="w-full lg:w-3/5 lg:pr-24">
											<div className="carousel-image" style={{
												backgroundSize: 'cover',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												backgroundImage:`url(${Calmness})`
											}}></div>
										</div>
										<div className="w-full lg:w-2/5 lg:pl-12">
											<p className="freight-bl text-2xl lg:text-4xl my-4" style={{
												lineHeight: '1.45',
												letterSpacing: '0.39px',
												color: '#454545'
											}}>
												The Garden
											</p>
											<p className="neue-roman lg:text-lg" style={{
												lineHeight: '1.61',
												letterSpacing: '1.53px',
												color: '#959595'
											}}>
												Our open fields garden overlooking the 
												<span className="neue-md"> Inner Space</span>, a perfect spot for outdoor events.
											</p>
											<div className="my-8" style={{
												width: 63.8,
												height: 0,
												border: 'solid 0.5px #454545'
											}}></div>
											<p className="freight-medium lg:text-lg" style={{
												lineHeight: '1.95',
												letterSpacing: '0.38',
												color: '#454545'
											}}>
												We grow and maintain the fields  with our in-house florists who 
												specialise in keeping the standards of our gardens to spec, this helps 
												maximise the natural theme of the centre. 
											</p>
											<button onClick={() => this.scrollToReserve()} className="my-4 neue-md px-6 py-3" style={{
												color: '#f5f5f5',
												fontSize: 12,
												letterSpacing: '1.95px',
												backgroundColor: '#454545'
											}}>
												RESERVE
											</button>
										</div>
									</div>
								</div>
								<div>
									<div className="flex flex-col lg:flex-row items-center">
										<div className="w-full lg:w-3/5 lg:pr-24">
											<div className="carousel-image" style={{
												backgroundSize: 'cover',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												backgroundImage:`url(${Essentials})`
											}}></div>
										</div>
										<div className="w-full lg:w-2/5 lg:pl-12">
											<p className="freight-bl text-2xl lg:text-4xl my-4" style={{
												lineHeight: '1.45',
												letterSpacing: '0.39px',
												color: '#454545'
											}}>
												Inner Space
											</p>
											<p className="neue-roman lg:text-lg" style={{
												lineHeight: '1.61',
												letterSpacing: '1.53px',
												color: '#959595'
											}}>
												Our modern indoor spaces provide the ambience
												suitable for indoor-clad events.  
											</p>
											<div className="my-8" style={{
												width: 63.8,
												height: 0,
												border: 'solid 0.5px #454545'
											}}></div>
											<p className="freight-medium lg:text-lg" style={{
												lineHeight: '1.95',
												letterSpacing: '0.38',
												color: '#454545'
											}}>
												Our ultra modern <span className="freight-b">400 person</span> - seater inner space 
												is highly equipped, and easily morphed to meet the 
												requirements of  events. With several shades of 
												lights, visualisation has never looked this good.
											</p>
											<button onClick={() => this.scrollToReserve()} className="my-4 neue-md px-6 py-3" style={{
												color: '#f5f5f5',
												fontSize: 12,
												letterSpacing: '1.95px',
												backgroundColor: '#454545'
											}}>
												RESERVE
											</button>
										</div>
									</div>
								</div>
							</Slider>
						</div>
					</div>
					<div className="w-full py-16 lg:py-32 px-4" style={{
						backgroundImage: 'linear-gradient(to bottom, #f5f5f5, rgba(255, 255, 255, 0))'
					}}>
						<div className="container mx-auto px-3">
							<div className="">
								<p className="freight-bl font-35" style={{
									lineHeight: '1.45',
									letterSpacing: '0.39',
									color: '#454545'
								}}>
									Our Services
								</p>
								<p className="neue-roman text-base" style={{
									maxWidth: 400,
									lineHeight: '1.61',
									letterSpacing: '1.08',
									color: '#a0a0a0'
								}}>
									Pearly Gate Gardens has other services to 
									offer aside from event hosting.
								</p>
								<div className="mt-16 mb-16 sm:mb-32" style={{
									maxWidth: 661.3,
									height: 0,
									border: 'solid 0.6px #a0a0a0'
								}}></div>

							</div>
							<div className="flex flex-wrap items-start justify-between md:-mx-3 lg:-mx-6">
								<div className="flex flex-col items-start w-full md:w-1/3 md:px-3 lg:px-6 mb-8 sm:mb-0">

									<div className="flex flex-row items-center md:items-start md:flex-col">
										<img className="w-8 lg:w-12" src={EventsIcon} alt=""/>
										<div className="freight-dl md:mt-6 mx-4 md:mx-0 text-3xl lg:text-5xl" style={{
											lineHeight: '1.96',
											letterSpacing: '0.86',
											color: '#454545'
										}}>
											Events
										</div>
									</div>
									<p className="freight-dl text-base lg:text-lg max-w-xs" style={{
										lineHeight: '1.67',
										letterSpacing: '0.38',
										color: '#7b7b7b'
									}}>
										We host a variety of events, ranging from 
										weddings, corporate, and private ceremonies. 
										Both the gardens and inner spaces are 
										available for above purposes
									</p>

								</div>
								<div className="flex flex-col items-start w-full md:w-1/3 md:px-3 lg:px-6 mb-8 sm:mb-0">

									<div className="flex flex-row items-center md:items-start md:flex-col">
										<img className="w-8 lg:w-12" src={AccessibilityIcon} alt=""/>
										<div className="freight-dl md:mt-6 mx-4 md:mx-0 text-3xl lg:text-5xl" style={{
											lineHeight: '1.96',
											letterSpacing: '0.86',
											color: '#454545'
										}}>
											Accessibility
										</div>
									</div>
									<p className="freight-dl text-base lg:text-lg max-w-xs" style={{
										lineHeight: '1.67',
										letterSpacing: '0.38',
										color: '#7b7b7b'
									}}>
										Fully equipped with a large car park for 
										attendees, wheelchair friendly environment, 
										washrooms as well as a large multipurpose 
										kitchen. 
									</p>

								</div>
								<div className="flex flex-col items-start w-full md:w-1/3 md:px-3 lg:px-6 mb-8 sm:mb-0">

									<div className="flex flex-row items-center md:items-start md:flex-col">
										<img className="w-8 lg:w-12" src={SecurityIcon} alt=""/>
										<div className="freight-dl md:mt-6 mx-4 md:mx-0 text-3xl lg:text-5xl" style={{
											lineHeight: '1.96',
											letterSpacing: '0.86',
											color: '#454545'
										}}>
											Security
										</div>
									</div>
									<p className="freight-dl text-base lg:text-lg max-w-xs" style={{
										lineHeight: '1.67',
										letterSpacing: '0.38',
										color: '#7b7b7b'
									}}>
										Most event centres outsource security 
										personnel to ensure safety, and an overall calm 
										atmosphere. Our centre is integrated with a 
										security team who are readily available.
									</p>

								</div>
							</div>
						</div>
					</div>
					<div className="w-full py-16 md:py-32 px-4" style={{
						backgroundImage: "linear-gradient(to bottom, #ffffff, #fff4ff)"
					}}>
						<div className="container mx-auto px-3">
							<div className="w-full sm:flex-row flex-col-reverse flex-col sm:max-w-4xl mx-auto flex items-end justify-center">
								<div className="flex sm:px-4 sm:px-0 flex-col sm:items-end sm:text-right sm:pr-20">
									<p className="freight-dl sm:mt-0 mt-4 text-2xl md:text-4xl xl:text-5xl" style={{
										lineHeight: '1.26',
										letterSpacing: '0.18',
										color: '#000000'
									}}>
										Our ultra - modern <span className="freight-book">Light & Glass</span> filled Inner
										Space
									</p>
									<div className="my-8" style={{
										width: 63.8,
										height: 0,
										border: 'solid 2px #454545'
									}}></div>
									<p className="freight-dl text-lg lg:text-xl" style={{
										lineHeight: '1.44',
										letterSpacing: '0.2',
										color: '#171717'
									}}>
										From customisable light shades to 
										matching decor, the centre is  
										equipped with the latest and the best 
										to bring your events to life.
									</p>
									<p className="neue-roman italic my-4 text-base lg:text-lg" style={{
										maxWidth: 240,
										letterSpacing: "0.14px",
										color: '#959595'
									}}>
										Event types / themes determine the 
										design style. 
									</p>
									<button onClick={() => this.setState({photosIsOpen: true})} className="neue-md px-6 py-3" style={{
										color: '#f5f5f5',
										fontSize: 12,
										color: '#171717',
										border: 'solid 1px #171717',
										letterSpacing: '1.95px',
										backgroundColor: 'transparent'
									}}>
										EXPLORE
									</button>
								</div>
								<div className="w-full">
									<div className="mx-auto w-full max-w-full essentials-image" style={{
										width: '420px',
										backgroundSize: 'cover',
										backgroundPosition: 'center',
										backgroundRepeat: 'no-repeat',
										backgroundImage:`url(${Essentials})`
									}}></div>
								</div>
							</div>
						</div>
					</div>
					<div className="w-full py-16 md:py-32 px-4" style={{
						backgroundColor: "#fbfaf9"
					}}>
						<div className="container mx-auto px-3">
							<div className="max-w-xl">
								<div className="sm:flex flex-col -mx-8">
									<div className="flex-1 px-8">
										<div className="mb-6 neue-roman" style={{
											fontSize: 18,
											lineHeight: '1.61',
											letterSpacing: '0.22',
											color: '#5c5c5c'
										
										}}>Discover Pearly Gate Gardens</div>
										<div className="leading-none freight-bl font-35 my-6 sm:my-0" style={{
											lineHeight: '1.22',
											letterSpacing: '0.39',
											color: '#454545'
										}}>
											A reel of our beautiful and ultra-modern event centre
											{/* A reel of some memorable Events we’ve hosted in Our gardens */}
										</div>
									</div>
									<div className="mx-8 my-8" style={{
										width: 63.8,
										height: 0,
										border: 'solid 1px #454545',
									}}></div>
									<div className="flex-1 px-8 freight-medium md:text-xl text-lg" style={{
										lineHeight: '1.67',
										letterSpacing: '0.38',
										color: '#959595'
									
									}}>
										With every event hosted on our premises, we keep reels
										of these cherished moments to share with our clients as 
										well as making them available in our archives. Moments
										like these are timeless.
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <Waypoint
						fireOnRapidScroll={true}
						scrollableAncestor={document.body}
						onEnter={this.onEnter}
						onLeave={this.onLeave}
						onPositionChange={(e) => console.log(e)}
						key="video"
					> */}
						<video onContextMenu={(e) => {

							e.preventDefault();

							return false;

						}} loop={true} autoPlay={true} playsInline={true} src={Video} muted={true} className="z-20" style={{
							width: '100%',
							overflow: 'hidden',
							objectFit: 'cover',
							// maxWidth: '500px',
							height:'auto'
						}}></video>
					{/* </Waypoint> */}
					{!loadingPosts && !posts.length ? null : <div className="py-16 px-4" style={{
						backgroundColor: '#fbfaf9'
					}}>
						<div className="container mx-auto px-3">
							<div className="">
								<div className="freight-bl sm:text-5xl text-3xl" style={{
									lineHeight: '1.22',
									letterSpacing: '0.39px',
									color: '#454545'
								}}>Essentials and Pearls</div>
								<div className="my-8" style={{
									width: 63.8,
									height: 0,
									border: 'solid 1px #454545'
								}}></div>
								<p className="freight-medium text-base sm:text-xl" style={{
									maxWidth: 519,
									lineHeight: '1.67',
									letterSpacing: '0.38px',
									color: '#959595'
								}}>
									{/* Take a stroll down the past events hosted within the premises. A variety 
									of events ranging from very personal weddings to full on private 
									executive parties. */}
									Take a stroll through stories and tips from seasoned event planners and event guests we’ve hosted.
								</p>
							</div>
							<div className="flex flex-wrap -mx-6 mt-10">
								{loadingPosts ? <div className="flex h-full items-center justify-center py-10">
									<Spin />
								</div>  : posts.map((e, index) => {

									const hasFeatured = e.featured_media !== 0;

									let image = hasFeatured ? e._embedded['wp:featuredmedia'].filter((i) => i.id === e.featured_media): null

									return (
										<div className="w-full my-6 md:w-1/2 lg:w-1/3 px-6" key={e.id}>
											<div style={{
												height: '280px',
												backgroundSize: 'cover',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												backgroundImage: image ? `url(${image[0].source_url})`: ''
											}} className="w-full bg-gray-200"></div>
											<div className="mt-6">
												<div className="freight-medium" style={{
													fontSize: 24,
													lineHeight: '1.29',
													letterSpacing: '0.26px',
													color: '#171717'
												}}>{e.title.rendered}</div>
												<div className="my-2 freight-dl" style={{
													fontSize: 18,
													lineHeight: '1.62',
													letterSpacing: '0.17px',
													color: '#5c5c5c'
												}} dangerouslySetInnerHTML={{__html: e.excerpt.rendered}}></div>
												<a target="_blank" href={`http://blog.pearlygategardens.com/${e.slug}`} className="py-3 neue-roman" style={{
													fontSize: 16,
													lineHeight: '1.18',
													letterSpacing: '0.77px',
													color: '#ff7045'
												}}>Read More</a>
											</div>
										</div>
									);

								})}
							</div>
							<div className="w-full h-1 mt-6 mb-12" style={{
								borderBottom: '0.3px solid #959595'
							}}></div>
							{totalPages > 3 ? <a target="_blank" href={`http://blog.pearlygategardens.com/`} className="neue-md px-6 py-3 uppercase block mx-auto" style={{
								color: '#f5f5f5',
								fontSize: 12,
								color: '#171717',
								border: 'solid 1px #171717',
								letterSpacing: '1.95px',
								backgroundColor: 'transparent'
							}}>
								More Stories
							</a>: null}
						</div>
					</div>}
					<div className="freight-dl" id="reserve" ref={this.reserveRef} style={{
						backgroundImage: 'linear-gradient(to bottom, #f5f5f5, rgba(245, 245, 245, 0))'
					}}>
						<div className="max-w-3xl w-full py-16 sm:py-32 mx-auto tracking-wider">
							<div className="flex flex-col px-6">
								{!reserveSent ? <React.Fragment>
									<div className="freight-bl mb-6" style={{
										fontSize: 35,
										lineHeight: 1.29,
										letterSpacing: 0.16,
										color: '#5c5c5c'
									
									}}>Reserve</div>
									<div className="w-full border form-border reserve-form">
										<div className="">
											<input value={eventName} onChange={(e) => this.setState({eventName: e.target.value})} style={{
												width: "100%",
												backgroundColor: "transparent"
											}} type="text" className="p-6 border-b form-border" placeholder="Full Name" />
										</div>
										<div className="sm:flex flex-col sm:flex-row border-b form-border">
											<div className="flex-1 border-b sm:border-b-0 sm:border-r form-border">
												<input type="text" value={eventEmail} onChange={(e) => this.setState({eventEmail: e.target.value})} style={{
													width: "100%",
													backgroundColor: "transparent"
												}} className="p-6" placeholder="Email" />
											</div>
											<div className="flex-1">
												<input value={eventPhone} onChange={(e) => this.setState({eventPhone: e.target.value})} type="text" style={{
													width: "100%",
													backgroundColor: "transparent"
												}} className="p-6" placeholder="Phone Number" />
											</div>
										</div>
										<div className="sm:flex flex-col sm:flex-row border-b form-border">
											<div className="flex-1 border-b sm:border-b-0 sm:border-r form-border">
												<select className="p-6" value={eventType || 'false'} onChange={(e) => this.setState({eventType: e.target.value})} style={{
													width: '100%',
													height: '100%',
													backgroundColor: 'transparent',
													color: '#A7A7A7'
												}}>
													<option value="false">Select Event type</option>
													<optgroup label="Weddings">
														<option value="Wedding Receptions">Wedding Receptions</option>
														<option value="Wedding Ceremonies">Wedding Ceremonies</option>
														<option value="Engagement Parties">Engagement Parties</option>
													</optgroup>
													<optgroup label="Corporate">
														<option value="Networking events">Networking events</option>
														<option value="Conferences">Conferences</option>
														<option value="Seminars">Seminars</option>
														<option value="EOFY">EOFY</option>
														<option value="Christmas functions">Christmas functions</option>
													</optgroup>
													<optgroup label="Private Parties & Events">
														<option value="Birthday parties">Birthday parties</option>
														<option value="Food bazaar">Food bazaar</option>
														<option value="Pop-up markets">Pop-up markets</option>
														<option value="Garden parties">Garden parties</option>
														<option value="Outdoor cinema">Outdoor cinema</option>
														<option value="Concert">Concert</option>
													</optgroup>
												</select>
											</div>
											<div className="flex-1">
												<DatePicker onChange={(d) => this.setState({eventDate: d.format('YYYY-MM-DD')})} style={{width: 77}} value={moment(eventDate, 'YYYY-MM-DD')} disabledDate={(date) => date.isSameOrBefore(moment())} format="DD MMMM, YYYY" className="p-6" style={{
													width: '100%',
													height: '100%'
												}} />
											</div>
										</div>
										<div className="border-b form-border">
											<textarea className="p-6" style={{
												width: "100%",
													backgroundColor: "transparent"
											}} placeholder="Information" value={eventInfo} name="" id="" cols="30" rows="5" onChange={(e) => this.setState({eventInfo: e.target.value})}></textarea>
										</div>
										<div className="px-4 sm:p-8 w-full" style={{
											color: "#A7A7A7"
										}}>
											<p className="uppercase neue-md" style={{
												lineHeight: '4.19',
												fontSize: 16,
												letterSpacing: '0.72',
												color: '#454545'
											}}>Privacy Policy</p>
											<p className="text-xs sm:text-base neue-light" style={{
												lineHeight: '1.81',
												letterSpacing: '1.04',
												color: '#5c5c5c'
											}}>
												Pearly Gate Gardens will use the information you provide on this form to stay in touch with you. We will treat 
												your information with confidentiality and will not share it with others. For more information, visit our 
												Privacy Policy page. By clicking below, you agree that we may process your information in accordance with 
												these terms.
											</p>
											<div className="flex items-center">
												<label htmlFor="">
													<input checked={eventAgree} onChange={(e) => this.setState({eventAgree: e.target.checked})} type="checkbox" name="" id=""/>
												</label>
												<span className="uppercase mx-4 neue-md" style={{
													lineHeight: '4.19',
													fontSize: 16,
													letterSpacing: '0.72',
													color: '#454545'
												}}>i agree</span>
											</div>
										</div>
									</div>
									<button onClick={this.makeReservation} disabled={!eventAgree || isReserving} className="flex items-center justify-center block mx-auto my-16 neue-md px-6 py-3" style={{
										color: !eventAgree ? '#9a9a9a' : '#f5f5f5',
										fontSize: 12,
										letterSpacing: '1.95px',
										minWidth: 195,
										backgroundColor: !eventAgree ? '#f8f8f8' : '#454545'
									}}>
										{!isReserving ? 'MAKE RESERVATION': <Spin />}
									</button>
								</React.Fragment>: <div className="">
									<div className="flex flex-col items-center sm:items-start sm:flex-row  -mx-6">
										<div className="text-green-500 px-6 mb-6 sm:mb-0">
											<CheckCircle size={48} />
										</div>
										<div className="px-6">
											<div className="freight-bl mb-4 sm:mb-6" style={{
												fontSize: 35,
												lineHeight: 1.29,
												letterSpacing: 0.16,
												color: '#5c5c5c'
											
											}}>Reservation Sent</div>
											<p className="text-xs sm:text-base neue-light" style={{
												lineHeight: '1.81',
												letterSpacing: '1.04',
												color: '#5c5c5c'
											}}>We have successfully received your reservation. We have also sent a confirmation to <span className="font-bold">{eventEmail}</span></p>
										</div>
									</div>
								</div>}
							</div>								
						</div>
						<div className="py-10 border-form px-4" style={{
							borderTopWidth: 0.5,
							color: "#A7A7A7",
							borderColor: "#707070",
							backgroundColor: '#202020',
						}}>
							<div className="container mx-auto">
								<div className="sm:flex flex-col sm:flex-row items-center justify-between">
									<div className="">
										<ul className="list-reset flex items-center justify-center sm:justify-start -mx-3">
											<li className="px-3">Privacy</li>
											<li className="px-3">Copyright</li>
											<li className="px-3">Disclaimer</li>
										</ul>
									</div>
									<div className="">
										<ul className="list-reset flex items-center justify-center sm:justify-start -mx-3">
											<li className="px-3">
												<a href="https://www.facebook.com/pearlygategardens/" target="_blank" className="">Facebook</a>
											</li>
											<li className="px-3">
												<a href="https://www.instagram.com/pearlygategardens/" target="_blank" className="">Instagram</a>
											</li>
											<li className="px-3 neue-light">
												<a href="tel:+233302934478" className="">+233 30 293 4478</a>
											</li>
										</ul>
									</div>
									<div className="text-center">
										Pearly Gate Garden &copy; {new Date().getFullYear()}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);

	}

}

export default App;
