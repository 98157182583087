import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Home from './Home';
import Contact from './Contact';
import Blog from './Blog';

class App extends React.Component{

    render() {
        
        return (
            <Switch>
                <Route component={Home} path="/" exact />
                <Route component={Blog} path="/blog" exact />
                <Route component={Contact} path="/contact" exact />
            </Switch>
        )

    }

}

export default App;