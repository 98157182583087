import React from 'react';

import LogoBlack from './images/logoblack.png';
import { X as Close, CheckCircle, ChevronRight} from 'react-feather';
import { slide as Menu } from 'react-burger-menu';
import {Link} from 'react-router-dom';
import { disableBodyScroll } from 'body-scroll-lock';

import {message, Spin} from 'antd';
import axios from 'axios';
import LogoWhite from './images/logowhite.png';


class Contact extends React.Component{

	state = {
        scrollPastHeader: true,
        eventName: '',
        eventPhone: '',
        eventEmail: '',
        eventInfo: '',
        reserveSent: false,
        isReserving: false
	}

	headerRef = React.createRef();
	reserveRef = React.createRef();
	eventsRef = React.createRef();
	mainRef = React.createRef();


	menu = () => (
		<div className="flex flex-col justify-around items-center h-full">
			<div className="hidden sm:block">
				<img src={LogoWhite} style={{
					width: 176,
					height: 38
				}} alt=""/>
			</div>
			<div className="">
				<ul className="flex freight-dl flex-col sm:flex-row items-center justify-center text-3xl -my-4 sm:-my-0 sm:-mx-4 h-full">
					<li><Link to="/blog" className="block py-4 sm:py-0 sm:px-4">Blog</Link></li>
					<li><Link to="/contact" className="block py-4 sm:py-0 sm:px-4">Contact</Link></li>
					{/* <li><a href="" className="block px-4">Events</a></li> */}
					{/* <li><a href="" className="block px-4">Essentials</a></li> */}
					<li><Link to="/#reserve" className="block py-4 sm:py-0 sm:px-4">Reserve</Link></li>
				</ul>
			</div>
			<div className="">
				<ul className="flex freight-dl items-center justify-center text-xl -mx-4 h-full">
					<li><a href="https://www.facebook.com/pearlygategardens/" target="_blank" className="block px-4">Facebook</a></li>
					<li><a href="https://www.instagram.com/pearlygategardens/" target="_blank" className="block px-4">Instagram</a></li>
				</ul>
			</div>
		</div>
		
	);
    
    componentDidMount() {

		disableBodyScroll(this.mainRef.current);

    }
    
    makeReservation = async () => {

		const {
			eventName,
			eventPhone,
			eventEmail,
			eventInfo,
		} = this.state;

		if (
			!eventInfo ||
			!eventName ||
			!eventPhone ||
			!eventEmail
		) {

			message.error('Kindly complete the reservation form to continue');

			return;

		}

		this.setState({isReserving: true});

		const url = "https://us-central1-domains-b539a.cloudfunctions.net/api/sendmail";

		try {

			const res = await axios.post(`${url}`, {
				receipient: eventEmail,
				type: 'Contact',
				eventName,
				eventPhone,
				eventEmail,
				eventInfo
			});

			this.setState({
				reserveSent: true,
				isReserving: false
			});

		} catch (e) {

			message.error('An error occurred, please try again');

			this.setState({
				isReserving: false
			});

		}

	}


	render() {

        const {
            scrollPastHeader,
            menuOpen,
            mobileMenu,
            eventName,
            eventPhone,
            eventEmail,
            eventInfo,
            reserveSent,
            isReserving
        } = this.state;
        
        return (
            <React.Fragment>
				<Menu styles={{
					bmMenuWrap: {
						width: '100vw',
						transform: 'translate3d(0px, -100%, 0px)'
					}
				}} isOpen={menuOpen} onStateChange={(e) => this.setState({menuOpen: e.isOpen})}>
					<div className="text-white h-full sm:block hidden" style={{
						backgroundColor: '#202020'
					}}>
						<div className="py-6">
							<a className="px-4 flex neue-roman justify-end items-center uppercase" href="#" onClick={(e) => {

								e.preventDefault();

								this.setState({menuOpen: false});

							}}>
								<span className="">
									Close
								</span>
								<span className="pl-3">
									<Close />
								</span>
							</a>
						</div>
						{this.menu()}
					</div>
				</Menu>
				<div ref={this.mainRef} style={{
                    backgroundColor: '#f5f5f5'
                }} className="main-view tracking-wide w-full h-full mx-auto">
				
					<div className={[
                        'fixed',
						"w-full",
						"z-30",
						mobileMenu ? 'h-screen': '',
						scrollPastHeader ? 'shadow' : ''
					].join(' ')} style={{
						backgroundColor: scrollPastHeader || mobileMenu ? '#fbfaf9': '',
						color: scrollPastHeader || mobileMenu ? '#171717': '#fff'

					}}>
						<div className="container mx-auto px-3 py-4">
							<div className="flex justify-between items-center">
								<div className="items-start montaga flex">
                                    <Link to="/">
                                        <img src={LogoBlack} style={{
                                            width: 176,
                                            height: 38
                                        }} alt=""/>
                                    </Link>
									{/* <div className="uppercase sm:text-lg">
										Pearly gate
									</div>
									<div className="hidden sm:inline uppercase px-2 text-xs">gardens</div> */}
								</div>
								{mobileMenu ? <a className="flex neue-roman justify-center items-center uppercase" href="#" onClick={(e) => {

									e.preventDefault();

									this.setState({mobileMenu: false});

									}}>
									<span className="">
										Close
									</span>
									<span className="pl-3">
										<Close />
									</span>
								</a> : <a className="inline sm:hidden" href="#" onClick={(e) => {

									e.preventDefault();

									this.setState({mobileMenu: true});

								}}>
									<div className="flex flex-col">
                                        <div style={{
                                            marginTop: '3px',
                                            marginBottom: '3px',
                                            width: '23px',
                                            height: 0,
                                            border: `solid 0.5px ${scrollPastHeader || mobileMenu ? '#171717': '#fff'}`
                                        }}></div>
                                        <div style={{
                                            marginTop: '3px',
                                            marginBottom: '3px',
                                            width: '23px',
                                            height: 0,
                                            border: `solid 0.5px ${scrollPastHeader || mobileMenu ? '#171717': '#fff'}`
                                        }}></div>
                                    </div>
								</a>}
								<ul className="hidden sm:flex list-reset items-center sm:-mx-4 neue-roman">
									<li className="hidden sm:inline uppercase"><a className="px-4" href="/#reserve" style={{
										fontSize: 15,
										lineHeight: '1.18',
										letterSpacing: '1.7px'
									}}>Reserve</a></li>
									<li className="uppercase">
										<a className="sm:px-4 flex items-center" href="#" onClick={(e) => {

											e.preventDefault();

											this.setState({menuOpen: !menuOpen});
											
										}}>
											<span className="" style={{
												fontSize: 15,
												lineHeight: '1.18',
												letterSpacing: '1.7px'
											}}>
												Menu
											</span>
											<span className="hidden sm:inline sm:pl-3">
												<div className="flex flex-col">
                                        <div style={{
                                            marginTop: '3px',
                                            marginBottom: '3px',
                                            width: '23px',
                                            height: 0,
                                            border: `solid 0.5px ${scrollPastHeader || mobileMenu ? '#171717': '#fff'}`
                                        }}></div>
                                        <div style={{
                                            marginTop: '3px',
                                            marginBottom: '3px',
                                            width: '23px',
                                            height: 0,
                                            border: `solid 0.5px ${scrollPastHeader || mobileMenu ? '#171717': '#fff'}`
                                        }}></div>
                                    </div>
											</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
						{mobileMenu ? <div className="sm:hidden h-screen" style={{
							backgroundColor: '#fbfaf9',
							color: '#171717'
						}}>
							<div className="container mx-auto px-3 sm:px-0 py-8 h-full">
								{this.menu()}
							</div>
						</div>: null}
					</div>
                    <div className="w-full flex flex-wrap pt-20 h-screen" style={{
                        
                    }}>
                        <div className="lg:w-1/2 w-full">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3970.538359970154!2d-0.1475164852336961!3d5.634938495915149!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf85418cc8b703%3A0x51c85e56dd186a4b!2sPearly%20Gate%20Gardens!5e0!3m2!1sen!2sgh!4v1567760671280!5m2!1sen!2sgh" width="800" height="600" frameborder="0" style={{
                                border: 0,
                                width: '100%',
                                height: '100%'
                            }} allowfullscreen=""></iframe>
                        </div>
                        <div className="lg:w-1/2 w-full p-4 lg:p-16">
                            <div className="w-full mx-auto freight-dl" style={{
                                maxWidth: 423
                            }}>
                                <h1 className="font-bold text-3xl text-black" style={{
                                    fontSize: 55
                                }}>Contact</h1>
                                <div className="py-6 mb-6 border-b neue-roman" style={{
                                    fontSize: 15
                                }}>
                                    <p className="font-bold">Pearly Gate Gardens</p>
                                    <p className="my-3">Boundary Road, Accra, Ghana.</p>
                                    <p className="flex flex-col">
                                        <a href="mailto:pearlygatesgarden@gmail.com" className="">pearlygatesgarden@gmail.com</a>
                                        {/* <span className="mx-2">|</span> */}
                                        <a href="mailto:info@pearlygategardens.com" className="">info@pearlygategardens.com</a></p>
                                </div>
                                <div className="flex sm:flex-row flex-col sm:items-center -mx-6 neue-roman" style={{
                                    fontSize: 15
                                }}>
                                    <p className="px-6"><span className="font-bold">CALL</span><a className="ml-1" href="tel:0302934478">030 293 4478</a></p>
                                    <p className="px-6"><span className="font-bold">WHATSAPP</span> <a className="ml-1" href="tel:0553723339">055 372 3339</a></p>
                                </div>
                                {!reserveSent ? <div className="contact-form mt-6 neue-roman">
                                    <input defaultValue={eventName} onChange={(e) => this.setState({eventName: e.target.value})} type="text" name="" placeholder="Name" id="" className="w-full p-4 my-2"/>
                                    <input defaultValue={eventEmail} onChange={(e) => this.setState({eventEmail: e.target.value})} type="text" name="" placeholder="Email" id="" className="w-full p-4 my-2"/>
                                    <input defaultValue={eventPhone} onChange={(e) => this.setState({eventPhone: e.target.value})} type="text" name="" placeholder="Phone" id="" className="w-full p-4 my-2"/>
                                    <textarea defaultValue={eventInfo} onChange={(e) => this.setState({eventInfo: e.target.value})} id="" cols="30" rows="5" placeholder="Message" id="" className="w-full p-4 my-2"></textarea>
                                    <button disabled={isReserving} onClick={(e) => this.makeContact()} className="w-full p-4 text-base text-white flex items-center justify-between" style={{
                                        backgroundColor: "#171717"
                                    }}>
                                        
                                        {!isReserving ? <span className="uppercase neue-md" style={{
                                            letterSpacing: '1.56px'
                                        }}>Send</span>: <Spin />}
                                        <span className=""><ChevronRight size={19} /></span>
                                    </button>
                                </div>: <div className="flex flex-col  -mx-6 my-6">
                                    <div className="text-green-500 px-6 mb-4">
                                        <CheckCircle size={28} />
                                    </div>
                                    <div className="px-6">
                                        <div className="freight-bl mb-4" style={{
                                            fontSize: 28,
                                            lineHeight: 1.29,
                                            letterSpacing: 0.16,
                                            color: '#5c5c5c'
                                        }}>Message Sent</div>
                                        <p className="text-xs sm:text-base neue-light" style={{
                                            lineHeight: '1.81',
                                            letterSpacing: '1.04',
                                            color: '#5c5c5c'
                                        }}>We have successfully received your message. We have also sent a confirmation to <span className="font-bold">{eventEmail}</span></p>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );

    }

}

export default Contact;
